import { isStringPresent, ConfigError } from './helper'

import {
  ANALYTICS_ENABLED,
  APP_PROTOCOL,
  COOKIE_SECURE,
  COOKIE_FUNCTIONAL_DOMAIN,
  COOKIE_SHARED_DOMAIN,
  ENVIRONMENT,
  FB_APP_ID,
  ID_OAUTH_ID,
  ID_URL,
  MAP_TILER_KEY,
  PLAUSIBLE_DOMAINS,
  PLAUSIBLE_CLIENT_API_HOST,
  PLAUSIBLE_CLIENT_API_PATH,
  POSTHOG_KEY,
  POSTHOG_HOST,
  PUBLIC_URL,
  RELEASE,
  SENTRY_CLIENT_DSN,
  SENTRY_EDGE_DSN,
  PIN_SHARING_URL,
  TWITTER_SITE,

  // deprecated
  CS_API_URL,
  ID_API_URL,
  NODE_ENV,
} from './ClientValues'

if (!isStringPresent(COOKIE_FUNCTIONAL_DOMAIN)) {
  throw new ConfigError('NEXT_PUBLIC_COOKIE_FUNCTIONAL_DOMAIN missing')
}

if (!isStringPresent(COOKIE_SHARED_DOMAIN)) {
  throw new ConfigError('NEXT_PUBLIC_COOKIE_SHARED_DOMAIN missing')
}

if (!isStringPresent(ENVIRONMENT)) {
  throw new ConfigError('NEXT_PUBLIC_ENVIRONMENT missing')
}

if (!isStringPresent(PUBLIC_URL)) {
  throw new ConfigError('NEXT_PUBLIC_PUBLIC_URL missing')
}

if (!isStringPresent(SENTRY_CLIENT_DSN)) {
  throw new ConfigError('NEXT_PUBLIC_SENTRY_CLIENT_DSN missing')
}

if (!isStringPresent(SENTRY_EDGE_DSN)) {
  throw new ConfigError('NEXT_PUBLIC_SENTRY_EDGE_DSN missing')
}

const ClientConfig = {
  ANALYTICS_ENABLED,
  APP_PROTOCOL,
  COOKIE_SECURE,
  COOKIE_FUNCTIONAL_DOMAIN,
  COOKIE_SHARED_DOMAIN,
  ENVIRONMENT,
  FB_APP_ID,
  ID_OAUTH_ID,
  ID_URL,
  MAP_TILER_KEY,
  PLAUSIBLE_DOMAINS: PLAUSIBLE_DOMAINS ?? 'app.vayapin.com,collect.vayapin.com',
  PLAUSIBLE_CLIENT_API_HOST: PLAUSIBLE_CLIENT_API_HOST ?? 'https://vaya-plau.vayapin.workers.dev',
  PLAUSIBLE_CLIENT_API_PATH: PLAUSIBLE_CLIENT_API_PATH ?? '/vayaplau/event',
  POSTHOG_KEY,
  POSTHOG_HOST,
  PUBLIC_URL,
  RELEASE,
  SENTRY_CLIENT_DSN,
  SENTRY_EDGE_DSN,
  PIN_SHARING_URL,
  TWITTER_SITE,

  /**
   * @deprecated Use ServerConfig.IDENTITY_SERVER_HOST instead. API calls to the identity server
   * should not be done from the browser. Instead create an API route here in next.
   */
  ID_API_URL,

  /**
   * @deprecated Use ServerConfig.CORE_SERVICE_SERVER_HOST instead. API calls to the core service server
   * should not be done from the browser. Instead create an API route here in next.
   */
  CS_API_URL,

  /**
   * @deprecated Use ClientConfig.ENVIRONMENT instead
   */
  NODE_ENV,
}

export default ClientConfig
