export function isStringPresent(str?: string | boolean): boolean {
  return isPresent(str) && str !== 'null' && str !== 'undefined'
}

export function isBooleanTrue(value: string): boolean {
  return value === 'true' || `${JSON.stringify(value || '')}` === 'true'
}

export function isPresent(value: unknown): value is string {
  return isString(value) && value.length > 0
}

export function isString(value: unknown): value is string {
  return typeof value == 'string'
}

export class ConfigError extends Error {
  constructor(message?: string, options?: ErrorOptions) {
    super(message, options)

    // necessary to assure "instanceof" checks
    Object.setPrototypeOf(this, ConfigError.prototype)

    if (Error.captureStackTrace) Error.captureStackTrace(this, ConfigError)
  }
}
